import React from 'react';
import { FiNavigation, FiCode, FiBook, FiPenTool, FiServer } from "react-icons/fi";

const Service = () => {
    return (
        <div className="rn-service-area rn-section-gapBottom">
            {/* Start Service Area  */}
            <div className="rn-service-area">
                <div className="container">
                    <div className="row">

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <FiCode />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Blockchain Solutions</h4>
                                        <p>We develop customized products using the latest web3 technologies.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <FiBook />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Education</h4>
                                        <p>We create tools and provide support to the community.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <FiServer />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Reliable Service</h4>
                                        <p>Our nodes work 24/7 and are maintained on a regular basis.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                    </div>
                </div>
            </div>
            {/* End Service Area  */}
            <br />
            <br />
            <br />
            <br />
            <p align="center"><iframe width="500" height="400" frameBorder="0" src="https://js.adapools.org/widget.html?pool=50de79c63d672319e20be59c620eb57ed1de8b7cc7fa489d8f22e353"><a href="https://adapools.org/pool/50de79c63d672319e20be59c620eb57ed1de8b7cc7fa489d8f22e353">Detail</a></iframe></p>

        </div>
    )
}

export default Service;