import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Projectcard from "./projectcard";

const ProjectOne = () => {
    const ProjectData = useStaticQuery(graphql`
        query ProjectDataQuery {
            allProjectJson(limit: 20) {
                edges {
                  node {
                    id
                    title
                    website
                    category
                    client
                    featured_image {
                        childImageSharp {
                            fluid(maxWidth: 374, maxHeight: 374, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                                presentationWidth
                                presentationHeight
                            }
                        }
                    }
                  }
                }
            }
        }
    `);

    const projectsData = ProjectData.allProjectJson.edges;

    for( var i = 0; i < projectsData.length; i++){
        if (!projectsData[i].node.client.localeCompare("Other")) {
            projectsData.splice(i, 1);
            i--; 
        }
    }

    return (
        <div className="row row--45 mt_dec--30">
            {projectsData.map( data => (
                <Projectcard key={data.node.id}
                    column="col-lg-4 col-md-6 col-12"
                    portfolioStyle="portfolio-style-1"
                    id={data.node.id}
                    website={data.node.website}
                    image={data.node.featured_image.childImageSharp}
                    title={data.node.title}
                    category={data.node.category}
                />
            ))}
        </div>
    )
}

export default ProjectOne;