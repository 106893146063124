import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/homedefault/banner";
import About from "../components/homedefault/about";
import Project from "../components/homedefault/project";
import Project2 from "../components/homedefault/project2";
import Service from "../components/homedefault/service";
import Spo from "../components/homedefault/spo";

const Index = () => (
  <Layout>
    <SEO title="Falcon Cardano Stakepool" />
    <Banner />
    <About />
    <Service />
    <div className="portfolio-id" id="portfolio1">
      <Project />
    </div>
    <div className="portfolio-id" id="portfolio2">
      <Project2 />
    </div>
    <Spo />
  </Layout>
)
export default Index;